<template>
  <v-container class="max-container">
    <v-card class="pa-3 card-box" flat dark color="black">
      <div class="profile-space" />
      <div v-show="step=='1'" class="profile-step profile-step-1">
        <div class="desc-text">
          <h3>You can setup your own profile to have:</h3>
          <ul>
            <li>own viewing history</li>
            <li>personalized recommendation</li>
            <li>PIN protection</li>
          </ul>
        </div>
        <div class="desc-img">
          <img src="https://www.onechannel.one/wp-content/uploads/2021/12/profile-setup2x.png">
        </div>
        <div>
          <v-btn block depressed class="tile text-capitalize sub-btn" @click="setStep('2')">Continue</v-btn>
        </div>
      </div>
      <div v-show="step=='2'" class="profile-step profile-step-2">
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-text-field
            v-model="profile.profile_name"
            autofocus
            small
            name="profile_name"
            :rules="profileRules"
            :label="'Profile'"
            :counter="14"
            required
            filled
            hint="Use a mix of letters, numbers & space, max 14 characters                                      "
            persistent-hint
            @input="textInput"
          />
        </v-form>
        <v-btn block depressed class="tile text-capitalize sub-btn" :loading="loading" @click="preSubmit">Submit</v-btn>
        <div class="desc-text step2-desc">
          <ul>
            <li>After logging into a channel, pick the profile under your name</li>
            <li>If your profile doesn’t exist yet, you can create one with your profile name</li>
            <li>Please do not delete other user's profile</li>
          </ul>
          <div class="note-desc">
            <h4>NOTE:</h4>
            <p>
              Any unrecognized profile will be automatically purged without notice.
            </p>
            <p>
              Changing profile name may cause your profiles to be deleted.
            </p>
          </div>
        </div>
      </div>

    </v-card>
    <c-snack-bar ref="csnackbar" />
    <over-dialog ref="overdialog" :ok-handle="submit" />
  </v-container>
</template>

<script>
import { getProfileInfo, updateProfileInfo } from '@/api/user'
import CSnackBar from './components/snackbar'
import OverDialog from './components/overDialog'

export default {
  name: 'Profile',
  components: {
    CSnackBar,
    OverDialog
  },
  data() {
    return {
      step: '1',
      loading: false,
      valid: true,
      profile: {
        profile_name: ''
      },
      profileRules: [
        v => {
          if (!v) {
            return 'profile is required'
          }

          if (v.trim().length > 14 || v.trim().length < 2) {
            return 'Use a mix of letters, numbers & space, max 14 characters'
          }

          var pattern = /^[0-9a-zA-Z\s]{2,14}$/
          if (!pattern.test(v.trim())) {
            return 'Use a mix of letters, numbers & space, max 14 characters'
          }

          return true
        }
      ]
    }
  },
  created() {
    this.setStep()
    this.getProfileInfo()
  },
  methods: {
    setStep(step) {
      if (!step) {
        this.step = window.location.hash === '#step-2' ? '2' : '1'
      } else {
        this.step = step
        window.location.hash = `#step-${step}`
      }
    },
    getProfileInfo() {
      getProfileInfo().then(res => {
        if (res.code === 0) {
          this.profile = res.data
        }
      })
    },
    submit() {
      if (!this.$refs.form.validate() || this.loading) {
        return
      }
      this.loading = true
      this.profile.profile_name = this.profile.profile_name.trim()
      updateProfileInfo(this.profile).then(res => {
        if (res.code === 4103) { // profile not match rules
          this.$refs.csnackbar.show(res.message, 'error', 5 * 1000)
          return
        }

        if (res.code === 4003) { // profile repeat
          this.$refs.csnackbar.show(res.message, 'info', 5 * 1000)
          return
        }
        if (res.code === 0) {
          this.$refs.csnackbar.show('Update profile success', 'success', 3 * 1000)
          setTimeout(() => {
            this.goTo()
          }, 1 * 1000)
        }
      }, () => {
        this.$refs.csnackbar.show('Something went wrong', 'error', 3 * 1000)
      }).finally(() => {
        this.loading = false
        this.$refs.overdialog.controlShowDialog(false)
      })
    },
    async goTo() {
      if (window.isAndroidWebView()) {
        window.AndroidCallback.onSubscriptionCallback('success')
        return
      }

      if (window.isWebKit()) {
        window.webkit.messageHandlers.refreshUserSubInfo.postMessage('success')
        return
      }

      await this.$store.dispatch('user/getInfo')
      this.$router.push({ name: 'mychannel' })
    },
    preSubmit() {
      if (!this.$refs.form.validate() || this.loading) {
        return
      }

      if (this.profile.id) {
        this.$refs.overdialog.controlDialog(true,
          'Changing profile name may cause your profiles to be deleted. Are you sure to continue?', 'Confirm')
        return
      }
      this.submit()
    },
    textInput(v) {

    }
  }
}
</script>

<style lang="scss" scoped>

.desc-text{
    ul{
        list-style: none;
        li{
            padding-top: 5px;
        }
        li::before{
            display: inline-block;
            content: "•";
            color: #E040FB;
            width: 1em;
            margin-left: -1em;
        }
    }

    // text-align: center;
}

.step2-desc{
 margin-top: 30px;
}

.note-desc{
    margin-top: 20px;
    margin-left: 10px;
    p{
        color: rgb(176 176 176);
    }
}

.desc-img{
    text-align: center;
    margin-top: 20px;
    img{
        height: 350px;
    }

}

.profile-space{
    height: 30px;
}

.sub-btn{
    margin-top: 30px;
    font-weight: bold;
    letter-spacing: 0.08em;
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-size: 800;
}

.input-tip{
    color: #ddd;
}

</style>
